import { message } from "antd";
import dayjs from "dayjs";
import md5 from "md5";
export const encryptPassword = (password: string): string => {
  return md5(password); // Using md5 to hash the password
};

export const buildQueryString = (params: Record<string, any>) => {
  return Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");
};
export const tofixedNumber = (
  str: string | number,
  fractionDigits: number = 3
) => {
  const num = str ? parseFloat(str.toString()) : 0;
  if (isNaN(num)) return "0";
  if (num === 0) return "0";
  return num.toFixed(fractionDigits).replace(/\.?0+$/, "");
};

export const onCopy = (text: string, msg?: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      message.info(msg || "复制成功");
    })
    .catch(() => {
      message.error("复制失败!");
    });
};

export const formateTime = (time?: string) => {
  return time ? dayjs(time).format("YYYY-MM-DD HH:mm:ss") : "--";
};

export const handleNumberInput = (
  e: React.FormEvent<HTMLInputElement>,
  handler: (
    value: string
  ) => void | React.Dispatch<React.SetStateAction<string>>
) => {
  let value = e.currentTarget.value;
  if (/^\d*\.?\d{0,5}$/.test(value) || /^\d+\.$/.test(value)) {
    value = value.replace(/[^0-9.]/g, "");
    handler(value);
  }
};
