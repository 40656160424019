import React, { useState } from 'react';
import Image from 'next/image';
import { Form, Input, Button, Row, Col, Checkbox, CheckboxProps } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'next-i18next';
import getLocaleProps from '@/helper/utils';
import { encryptPassword } from '@/helper/libs';
import Link from "next/link";
import { login, traderLogin } from '@/api/user';
import { useAuthStore } from '@/store/useAuthStore';
import AgreeTrems from '@/components/AgreeTrems';
import { Rightsmall } from '@/public/svgs/header';
import { useSettingStore } from '@/store/useSetting';
import { RES_CODE } from '@/helper/const';
export default function LoginPage() {
    const { t } = useTranslation(['register']);
    const { setToken, setUser, getUserInfo, oAuthParams } = useAuthStore()
    const { setIstrader, isTrader } = useSettingStore()

    const [loading, setLoading] = useState(false)






    const onFinish = (values: any) => {
        setLoading(true);

        const encryptedValues = {
            ...values,
            password: encryptPassword(values.password)
        };

        const reqFun = isTrader ? traderLogin : login

        reqFun(encryptedValues).then((res: any) => {
            if (res.code === RES_CODE.success) {
                setToken(res.data.token);
                // setUser(res.data.user);
                getUserInfo()
            }
        }).finally(() => {
            setLoading(false);
        });
    };




    return (

        // <div className="w-full md:w-1/2 flex items-center justify-center bg-white" >
        //     <div className="w-full max-w-md p-8">
        //         <h2 className="text-3xl font-semibold text-center mb-6">{t('register:login')}</h2>
        <>

            {/* <p className="text-Text-3 dark:text-Text-3 text-sm text-center"> 请使用你的邮箱登录</p> */}
            <Form
                name="login"
                layout="vertical"
                onFinish={onFinish}
                className="space-y-4 mt-5"
                validateTrigger="onBlur"

                autoComplete="new-password" // Disable form autofill

            >
                {/* 邮箱地址 */}
                <Form.Item
                    name="email"
                    label={t('register:email')}
                    rules={[{ required: true, message: `${t('register:pleaseInputEmail')}!` }, { type: 'email', message: `${t('register:isNotVaildEmail')}`, }]}
                    required={false}
                >
                    <Input placeholder={t('register:pleaseInputEmail')} />
                </Form.Item>


                {/* 密码 */}
                <Form.Item
                    name="password"
                    label={t('register:password')}
                    rules={[{ required: true, message: `${t('register:pleaseInputPassword')}!` }]}
                    required={false}
                >
                    <Input.Password placeholder={t('register:pleaseInputPassword')} />
                </Form.Item>

                <AgreeTrems></AgreeTrems>


                <Form.Item>
                    <Button id='login-button' type="primary" htmlType="submit" className="w-full text-white mt-5" loading={loading}>
                        {t('register:login')}
                    </Button>
                </Form.Item>
                <p className='text-xs mt-5 text-center text-Text-3 dark:text-dark-Text-3'>{t('register:notAccountYet')}  <Link className="text-Constant-G100 dark:text-white" href="/register">{t('register:toRegister')}</Link></p>

                <p className='text-Text-Brand text-center cursor-pointer flex-center justify-center' onClick={() => setIstrader(!isTrader)}>{isTrader ? "用户端入口" : "交易端入口"} <Rightsmall /> </p>
            </Form >
        </>
        //     </div>
        // </div >
    );
}

export const getStaticProps = getLocaleProps(["register", 'common']);


