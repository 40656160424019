import { Checkbox, theme } from 'antd'
import React, { useImperativeHandle, useState, forwardRef } from 'react'
import { useTranslation } from 'next-i18next'


type Props = {}

export interface AgreeTremsRef {
    getChecked: () => boolean;
}

const AgreeTrems = forwardRef<AgreeTremsRef, Props>((props, ref) => {
    const { token } = theme.useToken();

    console.log(token.colorBgContainer, 'tokentokentoken')
    const { t } = useTranslation('common');
    const [checked, setChecked] = useState(true);

    useImperativeHandle(ref, () => ({
        getChecked: () => checked
    }));

    return (
        <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} className="text-xs text-Text-3">
            {t('agreeTerms.agree')}
            <span className='text-Text-1 leading-5 dark:text-dark-Text-1'> {t('agreeTerms.productAgreement')} </span>
            {t('agreeTerms.and')}
            <span className="text-Text-1 dark:text-dark-Text-1"> {t('agreeTerms.riskWarning')} </span>
        </Checkbox>
    )
});

AgreeTrems.displayName = "AgreeTrems"
export default AgreeTrems